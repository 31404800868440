import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$vuetify.breakpoint.mdAndUp)?_c(VContainer,{attrs:{"grid-list-xl":"","pt-0":""}},[_c(VSubheader,{staticClass:"headline"},[_vm._v("\n        Favourite Views\n    ")]),_vm._v(" "),_c(VLayout,[(!_vm.hasFavouriteViews)?_c(VFlex,{attrs:{"xs12":"","sm6":"","md4":"","lg3":"","xl2":"","pt-5":""}},[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{attrs:{"elevation":hover ? 12 : 2}},[_c(VImg,{staticClass:"white--text",attrs:{"src":"static/img/stock_landscapes/stock9.jpg","height":"200","width":"300"}}),_vm._v(" "),_c(VCardTitle,{staticClass:"align-end fill-height"},[_vm._v("\n                        Favourite Views\n                    ")]),_vm._v(" "),_c(VCardText,[_vm._v("\n                        You don't have any favourite views. Click the star\n                        when you have a view open to add it your favourites\n                        and see it here.\n                    ")])],1)]}}],null,false,2359670315)})],1):_vm._e(),_vm._v(" "),(_vm.hasFavouriteViews)?_c(VFlex,{staticClass:"py-2"},[_c('transition-group',{attrs:{"name":"list","tag":"v-row"}},_vm._l((_vm.favouriteViews),function(view,i){return _c(VCol,{key:view.viewUuid,staticClass:"list-item",attrs:{"sm":6,"md":4,"lg":3,"xl":2}},[_c('recent-views-card',{staticClass:"list-item",attrs:{"view":view,"image-url":_vm.getImageUrl(i),"favourite":true},on:{"remove-from-favourites":_vm.removeFromFavourites}})],1)}),1)],1):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }