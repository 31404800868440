import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$vuetify.breakpoint.mdAndUp)?_c(VContainer,{attrs:{"grid-list-xl":"","pt-0":"","pb-0":""}},[_c(VSubheader,{staticClass:"headline"},[_vm._v("\n        Recent Views\n    ")]),_vm._v(" "),_c(VLayout,[(!_vm.hasRecentViews)?_c(VFlex,{attrs:{"xs12":"","sm6":"","md4":"","lg3":"","xl2":""}},[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{attrs:{"elevation":hover ? 12 : 2}},[_c(VImg,{staticClass:"white--text",attrs:{"src":"static/img/stock_landscapes/stock10.jpg","height":"200","width":"300"}}),_vm._v(" "),_c(VCardTitle,{staticClass:"align-end fill-height"},[_vm._v("\n                        Recently Used\n                    ")]),_vm._v(" "),_c(VCardText,[_vm._v("\n                        You don't have any recently used views. As you open\n                        and use assets, you'll start to see them here. Why\n                        not open a view?\n                    ")]),_vm._v(" "),_c(VCardActions,[_c(VBtn,{attrs:{"href":"/view","text":"","color":"primary"}},[_vm._v("\n                            Map View\n                        ")])],1)],1)]}}],null,false,2494203407)})],1):_vm._e(),_vm._v(" "),(_vm.hasRecentViews)?_c(VFlex,{staticClass:"py-2"},[_c(VSlideGroup,{staticClass:"ma-0 pa-0",style:((_vm.$vuetify.breakpoint.xl && _vm.recentViews.length <= 4) ||
                    (_vm.$vuetify.breakpoint.lg && _vm.recentViews.length > 3) ||
                    (_vm.$vuetify.breakpoint.md && _vm.recentViews.length > 2)
                        ? { width: '110%', left: '-64px' }
                        : { left: '-12px' }),attrs:{"next-icon":'$vuetify.icons.chevronRight',"prev-icon":'$vuetify.icons.chevronLeft',"show-arrows":""}},_vm._l((_vm.recentViews),function(view,i){return _c(VSlideItem,{key:view.viewUuid,staticClass:"mx-3 mt-5 mb-8 sm6 md4 lg3 xl2"},[_c('recent-views-card',{staticStyle:{"width":"272.25px"},attrs:{"view":view,"image-url":_vm.getImageUrl(i),"favourite":false,"disable":_vm.favViewsUuids.includes(view.viewUuid)},on:{"update-favourites":function($event){return _vm.$emit('update-favourites')},"add-to-favourites":_vm.addToFavourites}})],1)}),1)],1):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }