<template>
    <v-container v-if="$vuetify.breakpoint.mdAndUp" grid-list-xl pt-0>
        <v-subheader class="headline">
            Favourite Views
        </v-subheader>
        <v-layout>
            <v-flex v-if="!hasFavouriteViews" xs12 sm6 md4 lg3 xl2 pt-5>
                <v-hover v-slot:default="{ hover }">
                    <v-card :elevation="hover ? 12 : 2">
                        <v-img
                            class="white--text"
                            src="static/img/stock_landscapes/stock9.jpg"
                            height="200"
                            width="300"
                        />
                        <v-card-title class="align-end fill-height">
                            Favourite Views
                        </v-card-title>

                        <v-card-text>
                            You don't have any favourite views. Click the star
                            when you have a view open to add it your favourites
                            and see it here.
                        </v-card-text>
                    </v-card>
                </v-hover>
            </v-flex>
            <v-flex v-if="hasFavouriteViews" class="py-2">
                <transition-group name="list" tag="v-row">
                    <v-col
                        v-for="(view, i) in favouriteViews"
                        :key="view.viewUuid"
                        :sm="6"
                        :md="4"
                        :lg="3"
                        :xl="2"
                        class="list-item"
                    >
                        <recent-views-card
                            :view="view"
                            :image-url="getImageUrl(i)"
                            :favourite="true"
                            class="list-item"
                            @remove-from-favourites="removeFromFavourites"
                        />
                    </v-col>
                </transition-group>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import axios from 'axios';
import RecentViewsCard from 'components/Dashboard/ViewCard.vue';
import viewsInfoMixin from 'js/mixins/viewsInfoMixin';
import { findIndex } from 'lodash';

export default {
    name: 'FavouriteViews',
    components: {
        RecentViewsCard,
    },
    mixins: [viewsInfoMixin],
    props: {
        favViews: {
            default: null,
            type: Array,
        },
    },
    data() {
        return {
            favouriteViews: this.favViews,
            presentNumbers: [],
        };
    },
    computed: {
        hasFavouriteViews() {
            return this.favouriteViews.length > 0;
        },
    },
    watch: {
        favouriteViews() {
            this.$emit('change-favourites', this.favouriteViews);
        },
    },
    mounted() {
        let number;
        for (let i = 0; i < 11; i++) {
            number = Math.floor(Math.random() * 11) + 1;
            this.presentNumbers.push(number);
        }
    },
    methods: {
        getImageUrl(i) {
            return `static/img/stock_landscapes/stock${this.presentNumbers[i]}.jpg`;
        },
        update() {
            axios
                .get(`/api/get_favourite_views/`)
                .then(response => response.data)
                .then(data => {
                    this.favouriteViews = data.favouriteViews;
                })
                .catch(error => {
                    throw error;
                });
        },
        removeFromFavourites(viewUuid) {
            const index = findIndex(this.favouriteViews, {
                viewUuid,
            });
            this.favouriteViews.splice(index, 1);
        },
    },
};
</script>

<style scoped>
.list-item {
    display: inline-block;
}
.list-enter-active {
    transition: all 1s;
}
.list-leave-active {
    position: absolute;
    transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateY(30px);
}
.list-move {
    transition: transform 1s !important;
}

.headline {
    font-family: Omnes Regular, Omnes, Roboto, sans-serif !important;
}
</style>
