<template>
    <v-hover v-slot:default="{ hover }">
        <v-card
            height="410"
            :elevation="hover ? 6 : 2"
            style="overflow: hidden; border-radius: 8px; height: 370px"
        >
            <a :href="view.link">
                <v-img
                    :src="cardImage"
                    class="white--text"
                    height="200"
                    width="300"
                />
            </a>
            <v-card-title class="align-end pb-0">
                <div
                    v-line-clamp:20="2"
                    style="display:block; font-weight: 600"
                    class="assetName"
                >
                    <div style="position: absolute; top: 43%; right: 2%">
                        <template
                            v-if="
                                view_options[view.viewType].text == 'Inspection'
                            "
                        >
                            <p
                                style="background-color: #4B70F5; border-radius: 20px;  margin-right: 3px; margin-left: 2px!important; padding-bottom: 1px; margin-bottom: 3px!important; padding-left: 16px!important; padding-top: 1px!important; font-size: 12px!important; padding-right: 10px!important;"
                            >
                                <v-btn
                                    style="height: 4px; width: 4px"
                                    class="mx-1"
                                    fab
                                    dark
                                    small
                                    color="#3C64F4"
                                >
                                    <img src="~img/svgs/inspection.svg" />
                                </v-btn>
                                &nbsp;&nbsp; Inspection View
                            </p>
                        </template>

                        <template
                            v-if="
                                view_options[view.viewType].text ==
                                    'Thermal Inspection'
                            "
                        >
                            <p
                                style="background-color: #2A46AB; border-radius: 20px;  margin-right: 3px; margin-left: 2px!important; padding-bottom: 1px; margin-bottom: 3px!important; padding-left: 16px!important; padding-top: 1px!important; font-size: 12px!important; padding-right: 10px!important;"
                            >
                                <v-btn
                                    style="height: 30px; width: 30px"
                                    class="mx-1"
                                    fab
                                    dark
                                    small
                                    color="#2A46AB"
                                >
                                    <img src="~img/svgs/inspection.svg" />
                                </v-btn>
                                &nbsp;&nbsp; Inspection View
                            </p>
                        </template>

                        <template
                            v-if="
                                view_options[view.viewType].text == '3D Model'
                            "
                        >
                            <p
                                style="background-color: #3CA086; border-radius: 20px;  margin-right: 3px; margin-left: 2px!important; padding-bottom: 1px; margin-bottom: 3px!important; padding-left: 12px!important; padding-top: 1px!important; font-size: 12px!important; padding-right: 10px!important;"
                            >
                                <v-btn
                                    style="height: 4px; width: 4px"
                                    class="mx-1"
                                    fab
                                    dark
                                    small
                                    color="#3CA086"
                                >
                                    <img src="~img/svgs/3d.svg" />
                                </v-btn>
                                &nbsp;&nbsp; 3D Model
                            </p>
                        </template>

                        <template
                            v-if="view_options[view.viewType].text == '2D Map'"
                        >
                            <p
                                style="background-color: #6E4FC9; border-radius: 20px;  margin-right: 3px; margin-left: 2px!important; padding-bottom: 1px; margin-bottom: 3px!important; padding-left: 16px!important; padding-top: 1px!important; font-size: 12px!important; padding-right: 10px!important;"
                            >
                                <v-btn
                                    style="height: 4px; width: 4px"
                                    class="mx-1"
                                    fab
                                    dark
                                    small
                                    color="#6E4FC9"
                                >
                                    <img src="~img/svgs/2d.svg" />
                                </v-btn>
                                &nbsp;&nbsp; 2D Map
                            </p>
                        </template>

                        <template
                            v-if="
                                view_options[view.viewType].text ==
                                    'Point Cloud'
                            "
                        >
                            <p
                                style="background-color: #4B70F5; border-radius: 20px;  margin-right: 3px; margin-left: 2px!important; padding-bottom: 1px; margin-bottom: 3px!important; padding-left: 16px!important; padding-top: 1px!important; font-size: 12px!important; padding-right: 10px!important;"
                            >
                                <v-btn
                                    style="height: 4px; width: 4px"
                                    class="mx-1"
                                    fab
                                    dark
                                    small
                                    color="#3C64F4"
                                >
                                    <img src="~img/svgs/point-cloud.svg" />
                                </v-btn>
                                &nbsp;&nbsp; Point Cloud
                            </p>
                        </template>

                        <template
                            v-if="
                                view_options[view.viewType].text ==
                                    'Terrain'
                            "
                        >
                            <p
                                style="background-color: #ad3cf4; border-radius: 20px;  margin-right: 3px; margin-left: 2px!important; padding-bottom: 1px; margin-bottom: 3px!important; padding-left: 16px!important; padding-top: 1px!important; font-size: 12px!important; padding-right: 10px!important;"
                            >
                                <v-btn
                                    style="height: 4px; width: 4px"
                                    class="mx-1"
                                    fab
                                    dark
                                    small
                                    color="#ad3cf4"
                                >
                                    <img src="~img/svgs/terrain.svg" />
                                </v-btn>
                                &nbsp;&nbsp; Terrain View
                            </p>
                        </template>
                    </div>
                    {{ view.assetName }}
                </div>
            </v-card-title>
            <v-card-text class="mb-0 pb-1 pt-0">
                <div
                    v-line-clamp:20="2"
                    style="display:block;font-size: 15px; color: #70778A;"
                    class="siteName"
                >
                    {{ view.siteName }}
                </div>
            </v-card-text>

            <v-card-actions
                style="position: absolute;bottom: 0;"
                class="container  align-center"
            >
                <v-row class="align-center" justify-space-between>
                    <v-col>
                        <p class="ma-0">{{ view.datasetDate }}</p>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col style="margin-right: 3.3px">
                        <v-btn
                            icon
                            color="indigo"
                            :loading="loading"
                            :disabled="disable"
                            @click="toggleFavourite(view.viewUuid)"
                        >
                            <template v-if="favourite && !disable">
                                <img
                                    src="~img/svgs/star.svg"
                                    alt="star"
                                    width="19"
                                />
                                <span
                                    style="font-size: 14px!important; font-weight: bold!important"
                                    >Remove</span
                                >
                            </template>

                            <template v-if="disable">
                                <img
                                    src="~img/svgs/star-gold.svg"
                                    alt="star"
                                    width="19"
                                />
                                &nbsp;&nbsp;
                                <!--                <img src="~img/svgs/star-gold.svg" alt="star" width="19">-->
                                <span
                                    style="font-size: 13px!important; color: grey!important; font-weight: 600!important"
                                    >Favourited</span
                                >
                            </template>

                            <template v-if="!disable && !favourite">
                                <img
                                    src="~img/svgs/star.svg"
                                    alt="star"
                                    width="19"
                                />
                                &nbsp;&nbsp;
                                <!--                <img src="~img/svgs/star-gold.svg" alt="star" width="19">-->
                                <span
                                    style="font-size: 13px!important; color: grey!important; font-weight: 600!important"
                                    >Favourite</span
                                >
                            </template>
                        </v-btn>
                    </v-col>
                </v-row>

                <!--                <v-btn-->
                <!--                    :color="view_options[view.viewType].color"-->
                <!--                    :href="view.link"-->
                <!--                    style="color: white"-->
                <!--                    >Open</v-btn-->
                <!--                >-->
                <!--                <v-btn-->
                <!--                    :color="-->
                <!--                        disable-->
                <!--                            ? 'rgba(0, 0, 0, 0.15)'-->
                <!--                            : view_options[view.viewType].color-->
                <!--                    "-->
                <!--                    :loading="loading"-->
                <!--                    :disabled="disable"-->
                <!--                    outlined-->
                <!--                    @click="toggleFavourite(view.viewUuid)"-->
                <!--                >-->
                <!--                    <template v-if="favourite">-->
                <!--                        Remove Favourite-->
                <!--                    </template>-->
                <!--                    <template v-else>-->
                <!--                        Add Favourite-->
                <!--                    </template>-->
                <!--                </v-btn>-->
            </v-card-actions>
        </v-card>
    </v-hover>
</template>

<script>
import axios from 'axios';
import { trackDashboardEvents } from 'js/eventsTracking';
import viewsInfoMixin from 'js/mixins/viewsInfoMixin';

export default {
    name: 'RecentViewsCard',
    mixins: [viewsInfoMixin],
    props: {
        disable: {
            type: Boolean,
            default: false,
        },
        favourite: {
            type: Boolean,
            default: false,
        },
        view: {
            type: Object,
            default: null,
        },
        imageUrl: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        cardImage() {
            return this.view.image ? this.view.image : this.imageUrl;
        },
    },
    methods: {
        toggleFavourite(viewUuid) {
            this.loading = true;
            if (this.favourite) {
                this.removeFromFavourites(viewUuid);
            } else {
                this.addToFavourites(viewUuid);
            }
        },
        addToFavourites(viewUuid) {
            trackDashboardEvents.favouriteAddedDashboard(this.view);
            axios
                .post('api/add_favourite_view/', {
                    viewUuid,
                })
                .then(response => response.data)
                .then(() => {
                    this.$emit('add-to-favourites', viewUuid);
                    this.loading = false;
                })
                .catch(error => {
                    throw error;
                });
        },
        removeFromFavourites(viewUuid) {
            trackDashboardEvents.favouriteRemovedDashboard(this.view);
            axios
                .post(`api/remove_favourite_view/`, {
                    viewUuid,
                })
                .then(response => response.data)
                .then(() => {
                    this.$emit('remove-from-favourites', viewUuid);
                    this.loading = false;
                })
                .catch(error => {
                    throw error;
                });
        },
    },
};
</script>

<style>
body {
    font-family: Omnes Regular, Omnes, Roboto, sans-serif !important;
}
</style>
