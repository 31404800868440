<template>
    <v-container v-if="$vuetify.breakpoint.mdAndUp" grid-list-xl pt-0 pb-0>
        <v-subheader class="headline">
            Recently Uploaded Views
        </v-subheader>
        <v-layout>
            <v-flex v-if="!hasRecentlyUploadedViews" xs12 sm6 md4 lg3 xl2>
                <v-hover v-slot:default="{ hover }">
                    <v-card :elevation="hover ? 12 : 2">
                        <v-img
                            class="white--text"
                            src="static/img/stock_landscapes/stock10.jpg"
                            height="200"
                            width="300"
                        />
                        <v-card-title class="align-end fill-height">
                            Recently Uploaded Views
                        </v-card-title>
                        <v-card-text>
                            You don't have any recently uploaded views.
                        </v-card-text>
                        <v-card-actions>
                            <v-btn href="/view" text color="primary">
                                Map View
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-hover>
            </v-flex>
            <v-flex v-else class="py-2">
                <v-slide-group
                    :style="
                        ($vuetify.breakpoint.xl && views.length <= 4) ||
                        ($vuetify.breakpoint.lg && views.length > 3) ||
                        ($vuetify.breakpoint.md && views.length > 2)
                            ? { width: '110%', left: '-64px' }
                            : { left: '-12px' }
                    "
                    :next-icon="'$vuetify.icons.chevronRight'"
                    :prev-icon="'$vuetify.icons.chevronLeft'"
                    class="ma-0 pa-0"
                    show-arrows
                >
                    <v-slide-item
                        v-for="(view, i) in views"
                        :key="view.viewUuid"
                        class="mx-3 mt-5 mb-8 sm6 md4 lg3 xl2"
                    >
                        <view-card
                            style="width: 272.25px"
                            :view="view"
                            :image-url="getImageUrl(i)"
                            :favourite="false"
                            :disable="favViewsUuids.includes(view.viewUuid)"
                            @update-favourites="$emit('update-favourites')"
                            @add-to-favourites="addToFavourites"
                        />
                    </v-slide-item>
                </v-slide-group>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import ViewCard from 'components/Dashboard/ViewCard';
import { find, map } from 'lodash';

export default {
    name: 'RecentlyUploadedViews',
    components: {
        ViewCard,
    },
    props: {
        views: {
            default: () => [],
            type: Array,
        },
        favViews: {
            default: () => [],
            type: Array,
        },
    },
    data() {
        return {
            presentNumbers: [],
        };
    },
    computed: {
        hasRecentlyUploadedViews() {
            return this.views.length > 0;
        },
        favViewsUuids() {
            return map(this.favViews, 'viewUuid');
        },
    },
    mounted() {
        let number;
        for (let i = 0; i < this.views.length; i++) {
            do {
                number = Math.floor(Math.random() * 11) + 1;
            } while (
                this.presentNumbers[i - 1] === number ||
                this.presentNumbers[i + 1] === number
            );
            this.presentNumbers.push(number);
        }
    },
    methods: {
        getImageUrl(i) {
            return `static/img/stock_landscapes/stock${this.presentNumbers[i]}.jpg`;
        },
        addToFavourites(viewUuid) {
            this.$emit(
                'add-to-favourites',
                find(this.recentViews, { viewUuid })
            );
        },
    },
};
</script>

<style scoped>
.headline {
    font-family: Omnes Regular, Omnes, Roboto, sans-serif !important;
}
</style>
